import { http } from '@/lib/http'
import {
  ReconciliationMatchedType,
  ReconciliationSetListType,
  ReconciliationSetDetailType,
  ReconciliationMatchedItemType
} from '#/ReconciliationTypes'

export default {
  /**
   * 创建对账工作
   * @param {string} entityId 主体id
   * @param {Object} data
   * @property {string} data.description 描述
   * @property {string} data.direction 方向
   * @property {string} data.chartOfAccountId 科目id
   * @property {Array} data.transactionConditions 交易条件
   * @property {Array} data.ledgerConditions 分类账条件
   */
  createReconciliationSet: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/reconciliation/set`, {
      data
    })
  },
  /**
   * 修改对账工作
   * @param {string} entityId 主体id
   * @param {string} reconciliationSetId 对账工作id
   * @param {Object} data
   * @property {string} [data.description] 描述
   * @property {string} [data.direction] 方向
   * @property {string} [data.chartOfAccountId] 科目id
   * @property {Array} [data.transactionConditions] 交易条件
   * @property {Array} [data.ledgerConditions] 分类账条件
   */
  updateReconciliationSet: (entityId: string, reconciliationSetId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/reconciliation/set/${reconciliationSetId}`, {
      data
    })
  },
  /**
   * 删除对账工作
   * @param {string} entityId 主体id
   * @param {string} reconciliationSetId 对账工作id
   */
  deleteReconciliationSet: (entityId: string, reconciliationSetId: string) => {
    return http.request('delete', `/entity/${entityId}/reconciliation/set/${reconciliationSetId}`)
  },
  /**
   * 获取对账工作列表
   * @param {string} entityId 主体id
   * @param {Object} params
   * @param {number} params.page 页码
   * @param {number} params.limit 每页条数
   */
  getReconciliationSetList: (entityId: string, params: object) => {
    return http.request<ResponseData<ReconciliationSetListType>>('get', `/entity/${entityId}/reconciliation/set`, {
      params
    })
  },
  /**
   * 获取对账工作详情
   * @param {string} entityId 主体id
   * @param {string} reconciliationSetId 对账工作id
   */
  getReconciliationSetDetail: (entityId: string, reconciliationSetId: string) => {
    return http.request<ResponseData<ReconciliationSetDetailType>>(
      'get',
      `/entity/${entityId}/reconciliation/set/${reconciliationSetId}`
    )
  },
  /**
   * 创建对账规则
   * @param {string} entityId 主体id
   * @param {string} reconciliationSetId 对账工作id
   * @param {Object} data
   */
  createReconciliationRule: (entityId: string, reconciliationSetId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/reconciliation/set/${reconciliationSetId}/rule`, { data })
  },
  /**
   * 修改对账规则
   * @param {string} entityId 主体id
   * @param {string} reconciliationSetId 对账工作id
   * @param {string} reconciliationRuleId 对账规则id
   * @param {Object} data
   */
  updateReconciliationRule: (
    entityId: string,
    reconciliationSetId: string,
    reconciliationRuleId: string,
    data: object
  ) => {
    return http.request(
      'put',
      `/entity/${entityId}/reconciliation/set/${reconciliationSetId}/rule/${reconciliationRuleId}`,
      {
        data
      }
    )
  },
  /**
   * 删除对账规则
   * @param {string} entityId 主体id
   * @param {string} reconciliationSetId 对账工作id
   * @param {string} reconciliationRuleId 对账规则id
   */
  deleteReconciliationRule: (entityId: string, reconciliationSetId: string, reconciliationRuleId: string) => {
    return http.request(
      'delete',
      `/entity/${entityId}/reconciliation/set/${reconciliationSetId}/rule/${reconciliationRuleId}`
    )
  },
  /**
   * 执行规则对账
   * @param {string} entityId 主体id
   * @param {string} reconciliationSetId 对账工作id
   */
  executeReconciliationRule: (entityId: string, reconciliationSetId: string) => {
    return http.request('post', `/entity/${entityId}/reconciliation/set/${reconciliationSetId}/executeRules`)
  },
  /**
   * 获取已对账列表
   * @param {string} entityId 主体id
   * @param {string} reconciliationSetId 对账工作id
   * @param {Object} params
   * @param {number} params.page 页码
   * @param {number} params.limit 每页条数
   */
  getReconciliationMatchList: (entityId: string, reconciliationSetId: string, params: object) => {
    return http.request<ResponseData<ReconciliationMatchedType>>(
      'get',
      `/entity/${entityId}/reconciliation/set/${reconciliationSetId}/match`,
      { params }
    )
  },
  /**
   * 获取已对账详情
   * @param {string} entityId 主体id
   * @param {string} reconciliationSetId 对账工作id
   */
  getReconciliationMatchDetail: (entityId: string, reconciliationSetId: string) => {
    return http.request<ResponseData<ReconciliationMatchedItemType>>(
      'get',
      `/entity/${entityId}/reconciliation/match/${reconciliationSetId}`
    )
  },
  /**
   * 获取未对账交易列表
   * @param {string} entityId 主体id
   * @param {string} reconciliationSetId 对账工作id
   * @param {Object} params
   * @param {number} params.page 页码
   * @param {number} params.limit 每页条数
   */
  getReconciliationUnmatchedTrxList: (entityId: string, reconciliationSetId: string, params: object) => {
    return http.request('get', `/entity/${entityId}/reconciliation/set/${reconciliationSetId}/unmatchedTransaction`, {
      params
    })
  },
  /**
   * 获取未对账分类账列表
   * @param {string} entityId 主体id
   * @param {string} reconciliationSetId 对账工作id
   * @param {Object} params
   * @param {number} params.page 页码
   * @param {number} params.limit 每页条数
   */
  getReconciliationUnmatchedLedgerList: (entityId: string, reconciliationSetId: string, params: object) => {
    return http.request('get', `/entity/${entityId}/reconciliation/set/${reconciliationSetId}/unmatchedLedger`, {
      params
    })
  },
  /**
   * 批量解除对账匹配记录
   * @param {string} entityId 主体id
   * @param {string} reconciliationSetId 对账工作id
   * @param {Object} data
   * @param {string[]} data.reconciliationMatchIds 对账匹配id
   */
  batchReconciliationUnmatched: (entityId: string, reconciliationSetId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/reconciliation/set/${reconciliationSetId}/unmatch`, { data })
  },
  /**
   * 手动对账
   * @param {string} entityId 主体id
   * @param {string} reconciliationSetId 对账工作id
   * @param {Object} data
   * @param {string[]} data.transactionIds 页码
   * @param {string[]} data.generalLedgerIds 页码
   */
  manualReconciliation: (entityId: string, reconciliationSetId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/reconciliation/set/${reconciliationSetId}/match`, {
      data
    })
  },
  /**
   * 获取对账操作日志列表
   * @param {string} entityId 主体id
   * @param {string} reconciliationSetId 对账工作id
   * @param {Object} params
   * @param {number} params.page 页码
   * @param {number} params.limit 每页条数
   */
  getReconciliationSetLogList: (entityId: string, reconciliationSetId: string, params: object) => {
    return http.request('get', `/entity/${entityId}/reconciliation/set/${reconciliationSetId}/history`, { params })
  }
}
